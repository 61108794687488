<template>
  <div class="business-item" :class="{ __first: businessInfo.id === 'ID' }" @click="$emit('open', businessInfo)">
    <div class="business-item__id" :class="{ __first: businessInfo.id === 'ID' }">{{ businessInfo.id }}</div>
    <div class="business-item__title">
      <base-icon v-if="businessInfo.id !== 'ID'" path="gear.svg" />
      <div class="business-item__title-text truncate-ellipsis" :class="{ __first: businessInfo.id === 'ID' }">
        {{ businessInfo.firm_name }}
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import BaseIcon from "@/components/BaseIcon";

export default defineComponent({
  name: "MyBusinessItem",
  components: { BaseIcon },
  props: {
    businessInfo: {
      type: Object,
      required: true,
    },
  },
});
</script>
<style lang="scss" scoped>
.business-item {
  cursor: pointer;
  padding: var(--sp-3) var(--sp-14);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid var(--color-neutral-100);
  border-left: 2px solid var(--color-neutral-100);
  border-right: 2px solid var(--color-neutral-100);
  &.__first {
    border-top: 2px solid var(--color-neutral-100);
    cursor: default;
    border-radius: 5px 5px 0 0;
    background-color: var(--color-neutral-50);
  }
  &__id {
    &.__first {
      color: var(--color-neutral-500);
    }
    color: var(--color-neutral-600);
    width: 26px;
  }
  gap: var(--sp-3);
  &__title {
    gap: var(--sp-2);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__title-text {
    width: 130px;
    display: block;
    color: var(--color-primary-secondary);
    &.__first {
      color: var(--color-neutral-500);
    }
  }
}
</style>
