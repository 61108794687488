<template>
  <div class="base-confirm">
    <div class="base-confirm__wrapper">
      <div class="base-confirm__title mb-6">
        <div class="mr-2">{{ title }}</div>
        <base-icon-button
          v-if="showCloseButton"
          class="base-confirm__title-button"
          icon-path="Close-grey.svg"
          extra-small
          width="16"
          height="16"
          @click="$emit('close')"
        />
      </div>
      <slot name="default" />
    </div>
    <div class="base-confirm__cover"></div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import BaseIconButton from "@/components/BaseIconButton";

export default defineComponent({
  name: "BaseConfirm",
  components: { BaseIconButton },
  props: { title: { type: String, default: "" }, showCloseButton: { type: Boolean, default: true } },
});
</script>
<style lang="scss" scoped>
@import "../../styles/ui/fontSize";
.base-confirm {
  top: 0;
  left: 0;
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__wrapper {
    border-radius: 5px;
    background-color: var(--color-basic-white);
    position: absolute;
  }
  &__title {
    padding: var(--sp-6) var(--sp-6) 0 var(--sp-6);
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: Inter, serif;
    @include add-font-face("h4");
  }
  &__cover {
    width: 100%;
    height: 100%;
    background-color: rgba(32, 32, 32, 0.3);
  }
}
</style>
