<template>
  <div v-if="!showConfirm" class="business">
    <base-button
      class="business__add-business mb-6"
      is-new
      primary
      small
      @click="
        () => {
          showConfirm = true;
          isUpdate = false;
        }
      "
    >
      Создать пользователя
    </base-button>
    <my-business-list :business-list="businessList" @openBusinessInfo="openBusiness" :is-update="isUpdate" />
  </div>
  <div v-else class="business__info-wrapper">
    <business-info
      :business-info="userInfo"
      @openBusinessInfo="openBusiness"
      :is-update="isUpdate"
      @deleteBusiness="
        () => {
          showDeleteConfirm = true;
        }
      "
      @updateBusiness="updateBusiness"
      @addNewBusiness="addNewBusiness"
      @cancel="
        () => {
          userInfo.pass = '';
          userInfo.login = '';
          userInfo.firm_name = '';
          showConfirm = false;
        }
      "
    />
    <base-confirm
      v-if="showDeleteConfirm"
      :title="`Удалить пользователя ${userInfo.firm_name}?`"
      @close="showDeleteConfirm = false"
      :show-close-button="false"
    >
      <template #default>
        <div class="business__delete-confirm">
          <div class="business__delete-confirm-text px-6 pb-6">
            {{
              `Это действие невозможно отменить.Это приведет к необратимому удалению ${userInfo.firm_name} учетной записи и данных с
            наших
            серверов.`
            }}
          </div>
          <div class="business__confirm-buttons-divider"></div>
          <div class="business__confirm-buttons-wrapper mt-4 px-6 pb-4">
            <base-button
              class="business__confirm-button "
              is-new
              tertiary
              small
              @click="
                () => {
                  showConfirm = true;
                  showDeleteConfirm = false;
                }
              "
            >
              Отмена
            </base-button>
            <base-button class="business__delete-confirm-button __red" is-new primary small @click="deleteBusiness">
              Удалить
            </base-button>
          </div>
        </div>
      </template>
    </base-confirm>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import BaseButton from "@/components/BaseButton";
import MyBusinessList from "@/components/MyBusinessList";
import BaseConfirm from "@/components/BaseConfirm";
import BaseInput from "@/components/BaseInput";
import { request } from "@/components-js/requestSrv";
import BusinessInfo from "@/views/BusinessInfo.vue";

export default defineComponent({
  name: "MyBusiness",
  components: { BusinessInfo, BaseInput, BaseConfirm, MyBusinessList, BaseButton },
  setup() {
    const isUpdate = ref(false);
    const businessList = ref([{ id: "ID", firm_name: "Название" }]);
    const showConfirm = ref(false);
    const userInfo = ref({ firm_name: "", login: "", pass: "", id: "" });
    const showDeleteConfirm = ref(false);

    const openBusiness = businessInfo => {
      isUpdate.value = true;
      userInfo.value.id = businessInfo.id;
      userInfo.value.firm_name = businessInfo.firm_name;
      userInfo.value.login = businessInfo.login;
      userInfo.value.pass = businessInfo.pass;
      showConfirm.value = true;
    };
    const getData = async () => {
      const res = await request("/api/client/list");
      businessList.value = businessList.value.filter(item => item.id === "ID");
      businessList.value.push(...res);
    };
    const addNewBusiness = async () => {
      if (userInfo.value.firm_name === "" || userInfo.value.login === "" || userInfo.value.pass === "") return;
      try {
        const checkUnique = await request("/api/client/checkunique", "POST", { login: userInfo.value.login });
        if (checkUnique[0].cnt !== 0) return;
        await request("/api/client/insert", "POST", {
          firm_name: userInfo.value.firm_name,
          comment: "",
          login: userInfo.value.login,
          pass: userInfo.value.pass,
          status: 1,
        });
      } catch (e) {
        console.log(e);
      } finally {
        await getData();
        showConfirm.value = false;
      }
    };

    const deleteBusiness = async () => {
      try {
        const checkDelete = await request("/api/client/deletecheck", "POST", { id: userInfo.value.id });
        if (checkDelete[0].cnt !== 0) return;
        await request("/api/client/delete", "DELETE", { id: userInfo.value.id });
      } catch (e) {
        console.log(e);
      } finally {
        await getData();
        showConfirm.value = false;
        showDeleteConfirm.value = false;
      }
    };

    const updateBusiness = async () => {
      try {
        await request("/api/client/update", "POST", {
          id: userInfo.value.id,
          firm_name: userInfo.value.firm_name,
          comment: "",
          login: userInfo.value.login,
          pass: userInfo.value.pass,
          status: 1,
        });
      } catch (e) {
        console.log(e);
      } finally {
        await getData();
        showConfirm.value = false;
      }
    };

    onMounted(async () => {
      await getData();
    });

    return {
      isUpdate,
      userInfo,
      showConfirm,
      businessList,
      showDeleteConfirm,
      openBusiness,
      updateBusiness,
      deleteBusiness,
      addNewBusiness,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../styles/ui/fontSize";
.business {
  padding: var(--sp-6);
  &__info-wrapper {
    width: 100%;
  }
  &__add-business {
    width: 189px;
  }
  &__delete-business {
    &.__red {
      background: var(--Red-Light-bg, #ffe7ef);
      color: var(--Red-Dark-main, #ad0015);
      &:hover {
        box-shadow: inset 0 0 0 2px var(--Red-Dark-main, #ad0015);
        background: var(--Red-Light-bg, #ffe7ef);
        color: var(--Red-Dark-main, #ad0015);
      }
    }
  }
  &__confirm-button {
    width: 100%;
  }
  &__confirm-buttons-divider {
    background-color: var(--color-neutral-100);
    height: 1px;
    width: 100%;
  }
  &__confirm-buttons-wrapper {
    gap: var(--sp-6);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__confirm-form {
    display: flex;
    gap: 24px;
  }
  &__delete-confirm {
    max-width: 390px;
  }
  &__delete-confirm-button {
    &.__red {
      width: 100%;
      background: var(--Red-Light-bg, #ffe7ef);
      color: var(--Red-Dark-main, #ad0015);
      &:hover {
        box-shadow: inset 0 0 0 2px var(--Red-Dark-main, #ad0015);
        background: var(--Red-Light-bg, #ffe7ef);
        color: var(--Red-Dark-main, #ad0015);
      }
    }
  }
  &__delete-confirm-text {
    @include add-font-face("body-2");
  }
}
</style>
