<template>
  <div class="business-info mt-4 d-flex">
    <div class="business-info__form mb-6 px-6">
      <div class="business-info__title">
        <base-button
          class="business-info__back"
          transparent
          is-new
          small
          @click="$emit('cancel')"
        >
          <base-icon
            path="CaretLeftActive.svg"
            :width="16"
            :height="16"
            :screen-size-dependency="true"
          />
          {{ `Назад` }}
        </base-button>
        <div class="business-info__title-text">Редактирование {{ businessInfo.firm_name }}</div>
      </div>
      <div class="business-info__info mt-6">
        <base-input
          label="Название компании"
          :value="businessInfo.firm_name"
          required
          :max-length="30"
          @input="e => (businessInfo.firm_name = e.target.value)"
        />
        <base-input
          label="Логин"
          :value="businessInfo.login"
          required
          @input="e => (businessInfo.login = e.target.value)"
        />
        <base-input
          label="Пароль"
          :value="businessInfo.pass"
          required
          @input="e => (businessInfo.pass = e.target.value)"
        />
        <base-button
          v-if="isUpdate"
          class="business-info__delete-business __red"
          is-new
          small
          secondary
          @click="$emit('deleteBusiness')"
        >
          Удалить пользователя
        </base-button>
        <div class="business-info__confirm-buttons-divider"></div>
        <div class="business-info__confirm-buttons-wrapper mt-4 pb-4">
          <base-button class="business__confirm-button" is-new tertiary small @click="$emit('cancel')">
            Отмена
          </base-button>
          <base-button
            v-if="isUpdate"
            :disabled="
              businessInfo.pass === defaultBusinessInfo.pass &&
                businessInfo.firm_name === defaultBusinessInfo.firm_name &&
                businessInfo.login === defaultBusinessInfo.login
            "
            class="business-info__confirm-button"
            is-new
            primary
            small
            @click="$emit('updateBusiness')"
          >
            Обновить
          </base-button>
          <base-button
            v-else
            class="business-info__confirm-button"
            :disabled="businessInfo.pass === '' || businessInfo.firm_name === '' || businessInfo.login === ''"
            is-new
            primary
            small
            @click="$emit('addNewBusiness')"
          >
            Создать
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";
import BaseIcon from "@/components/BaseIcon/BaseIcon.vue";
export default defineComponent({
  name: "BusinessInfo",
  components: { BaseIcon, BaseButton, BaseInput },
  props: {
    businessInfo: {
      type: Object,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const defaultBusinessInfo = {
      login: props.businessInfo.login,
      pass: props.businessInfo.pass,
      firm_name: props.businessInfo.firm_name,
    };
    return {
      defaultBusinessInfo,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../styles/ui/fontSize";
.business-info {
  width: 100%;
  &__form {
    align-items: center;
  }
  &__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__back {
    display: flex;
    flex-direction: row;
    margin-right: auto;
  }
  &__title-text {
    @include add-font-face("h3");
    width: 75%;
  }
  &__info {
    gap: 24px;
    width: 50%;
  }
  &__delete-business {
    &.__red {
      width: 228px;
      background: var(--Red-Light-bg, #ffe7ef);
      color: var(--Red-Dark-main, #ad0015);
      &:hover {
        box-shadow: inset 0 0 0 2px var(--Red-Dark-main, #ad0015);
        background: var(--Red-Light-bg, #ffe7ef);
        color: var(--Red-Dark-main, #ad0015);
      }
    }
  }
  &__confirm-buttons-divider {
    background-color: var(--color-neutral-100);
    height: 1px;
    width: 100%;
  }
  &__confirm-buttons-wrapper {
    flex-direction: row;
    justify-content: end;
    gap: 24px;
  }
}
</style>
