<template>
  <div>
    <my-business-item
      v-for="(businessItem, index) in businessList"
      :business-info="businessItem"
      :key="index"
      @open="$emit('openBusinessInfo', businessItem)"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import MyBusinessItem from "./components/MyBusinessItem";

export default defineComponent({
  name: "MyBusinessList",
  components: { MyBusinessItem },
  props: {
    businessList: {
      type: Array,
      required: true,
    },
  },
});
</script>
